<script>
import PageHeader from "@/components/page-header";
import { ApiClientSelectedPlatform } from "@/common/http-common"
import { BredcrumpManagerDecode } from "@/common/bredcrumb-common"
import { networkRequesErrorResponseLogic } from "@/common/http-common-response-error"
import { networkRequesListResponseLogic } from "@/common/http-common-response-list"

export default {
  components: {
    PageHeader
  },
  data() {
    return {
      title: this.$t('permissions.permissions'),
      items: [],
      permissionsList:null,
      pagination: {
        total: 0,
        totalPages: 0,
        page: 1,
        pageSize: 10,
        nextPages: [],
        backPages: []
      }

    };
  },
  watch: {
    $route (to,from){
      if (to.name == "permission" && from.name == "platform/permission")
      window.location.reload(true);
    }
  },
  methods: {
    checkPermission: function (response, p) {
      var returnValue = false
      response.data.item.permissions.forEach(function (permission) {
        if (permission.permission.type == p) {
          returnValue = true
        }
      })
      return returnValue
    },
    getPermissions: function () {
      document.getElementById("preloader").style.display = "block";
      document.getElementById("status").style.display = "block";
      var rs = JSON.parse(atob(this.$route.query.data))
      ApiClientSelectedPlatform(rs.platformId).get("/v1/admin/user/permission").then((response) => {
        this.permissionsList = response
        if (this.$route.query.data) {
          var data = BredcrumpManagerDecode(this)
          if (data) {
            this.pagination.page = parseInt(data.page)
            this.pagination.pageSize = parseInt(data.size)
            this.getData(this.pagination.page, this.pagination.pageSize)
          } else {
            this.getData(this.pagination.page, this.pagination.pageSize)
          }
        } else {
          this.getData(this.pagination.page, this.pagination.pageSize)
        }


      }).catch(
        function (error) {
          networkRequesErrorResponseLogic(self, error)
        }
      )
    },
    getData: function (page, size) {
      const self = this;
      document.getElementById("preloader").style.display = "block";
      document.getElementById("status").style.display = "block";
      var rs = JSON.parse(atob(this.$route.query.data))
      ApiClientSelectedPlatform(rs.platformId).get("/v1/admin/domains/item/permissions/list?page=" + page + "&size=" + size).then(function (response) {
        networkRequesListResponseLogic(self, response, page, size, "permissions.permissions")
      }).catch(
        function (error) {
          networkRequesErrorResponseLogic(self, error)
        }
      )
    },
    getPreviousTwoPages() {
      const currentPage = this.pagination.page;
      let previousTwoPages = [];
      for (let i = currentPage - 1; i >= 1 && i >= currentPage - 2; i--) {
        previousTwoPages.unshift(i);
      }
      return previousTwoPages;
    },
    getNextTwoPages() {
      const currentPage = this.pagination.page;
      const totalPages = this.pagination.totalPages;

      // Calculate the next two pages
      let nextTwoPages = [];
      for (let i = currentPage + 1; i <= totalPages && i <= currentPage + 2; i++) {
        nextTwoPages.push(i);
      }

      return nextTwoPages;
    },
     configureDataNextModule:function(id){
      if (this.$route.query.data){
        var rs = JSON.parse(atob(this.$route.query.data))
        rs.itemId = id
        return btoa(JSON.stringify(rs))
      }   
    },
    configureLinkNextModule:function(url){
        if (this.$route.path.includes("platform/detail/")){
          return "/platform/detail/"+url
        }else {
          return url
        }
    }
  },
  mounted() {
    this.getPermissions()
  }
};
</script>
<template>
  <div class="row">
    <div class="col-xl-12" style="padding: 0!important;">
        <PageHeader :title="title" :items="items" />
      </div>
    <div class="col-xl-12" v-if="permissionsList != null">
      <div class="card">
            <div class="card-body">
              <h4 class="card-title">{{ this.$t('permissions.permissions') }}</h4>
              <p class="card-title-desc">
                <router-link v-if="checkPermission(permissionsList,'MODULE_WRITE_DOMAIN_PERMISSIONS')"
                              :to="{ path: configureLinkNextModule('permission/create'), query: { data: configureDataNextModule(null)} }">{{ $t("permissions.create") }}</router-link>
              </p>

              <div class="table-responsive">
                <table class="table mb-0">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th> {{ this.$t("permissions.list.permission") }}
                      </th>
                      <th>
                        </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in items" :key="index">
                      <td>{{ item.id }}</td>
                      <td>{{ item.title }}</td>
                      <td><router-link v-if="(item.default != true && checkPermission(permissionsList,'MODULE_WRITE_DOMAIN_PERMISSIONS') == true) || checkPermission(permissionsList,'MODULE_SUPER') == true"
                              :to="{ path: configureLinkNextModule('permission/update'), query: { data: configureDataNextModule(item.id)} }">{{ $t("permissions.update") }}</router-link></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="row mt-4" v-if="pagination.totalPages != 1 && items.length != 0">
                <div class="col-sm-6">
                  <div>
                    <p class="mb-sm-0 mt-2">
                      {{ this.$t("pagination.page") }}
                      <span class="font-weight-bold">{{ pagination.page }}</span> {{ this.$t("pagination.from") }}
                      <span class="font-weight-bold">{{ pagination.totalPages }}</span>
                    </p>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="float-sm-right">
                    <ul class="pagination pagination-rounded mb-sm-0">
                      <li class="page-item" v-bind:class="(pagination.page == 1) ? 'disabled' : ''">
                        <a href="#" class="page-link"
                          @click="getData(parseInt(pagination.page) - 1, pagination.pageSize)">
                          <i class="mdi mdi-chevron-left"></i>
                        </a>
                      </li>
                      <li class="page-item" v-for="item in pagination.backPages" :key="item">
                        <a href="#" class="page-link" @click="getData(item, pagination.pageSize)">{{ item }}</a>
                      </li>
                      <li class="page-item active">
                        <a href="#" class="page-link">{{ pagination.page }}</a>
                      </li>
                      <li class="page-item" v-for="item in pagination.nextPages" :key="item">
                        <a href="#" class="page-link" @click="getData(item, pagination.pageSize)">{{ item }}</a>
                      </li>
                      <li class="page-item" v-bind:class="(pagination.page == pagination.totalPages) ? 'disabled' : ''">
                        <a href="#" class="page-link" @click="getData(parseIn(pagination.page) + 1, pagination.pageSize)">
                          <i class="mdi mdi-chevron-right"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
    </div>
  </div>
</template>